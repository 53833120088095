<template>
  <div>
    <h1 class="unicorn-h1 mt-4">Архив заявок</h1>

    <div class="my-8 flex flex-col md:flex-row md:items-center">
      Выбор даты

      <u-date-picker
        class="md:ml-4 md:mt-0"
        v-model="date"
        :max-date="currentDate"
        title-position="left"
        :model-config="modelConfig"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <u-button block v-on="inputEvents">
            {{ inputValue ? `${inputValue}` : "Выбор даты" }}
          </u-button>
        </template>
      </u-date-picker>
    </div>

    <div class="flex flex-col" v-if="learners.length">
      <template v-for="learner in learners">
        <ArchiveRequestEntry :learner="learner" :key="learner.id" />
        <u-divider :key="`divider_${learner.id}`" />
      </template>
    </div>

    <div class="px-4 my-4" v-else>
      <u-alert icon="mdi-information-outline" variant="info">
        <p>
          Заявок на выбранный диапазон дат не найдено :( Попробуйте обновить
          страницу или изменить диапазон дат.
        </p>
      </u-alert>
    </div>
  </div>
</template>

<script>
import { sortBy } from "lodash";
import Api from "../services/Api";

export default {
  data() {
    return {
      learners: [],

      date: "",
      currentDate: "",

      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD" // Uses 'iso' if missing
      }
    };
  },

  watch: {
    async date(date) {
      await this.loadArchive(this.classId, date);
    }
  },

  computed: {
    classId() {
      return this.$route.params.classId;
    }
  },

  created() {
    this.getDate();
  },

  methods: {
    async getDate() {
      const { data: timestamps } = await Api.time();

      const date = new Date(timestamps);
      const stringDate = date
        .toLocaleDateString()
        .split(".")
        .reverse()
        .join("-");

      this.currentDate = stringDate;
      this.date = stringDate;
    },

    async loadArchive(classId, date) {
      const { data: learners } = await Api.classes.getClassLearners(
        classId,
        date
      );
      this.learners = sortBy(learners, ["surname", "name"]);
    }
  }
};
</script>
